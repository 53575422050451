import { z } from 'zod';

import { countryMap } from '@/layers/core/domain/countryMap';

type CountryAbbr = keyof typeof countryMap;

const countries = Object.keys(countryMap) as [CountryAbbr, ...CountryAbbr[]];

const Envs = z.object({
  quickApi: z.string().url(),
  homologatorsApi: z.string().url(),
  webhooksApi: z.string().url(),
  pushNotifications: z.string().url(),
  rtl: z.string().url(),
  mapsApiKey: z.string(),
  grecaptchaApiKey: z.string(),
  S3: z.string().regex(/[^/]$/),
  logWH: z.preprocess((v) => v || undefined, z.string().url().optional()),
  orderBilledUrl: z.string().url(),
  dashboard: z
    .string()
    .url()
    .regex(/theme=$/),
  chat: z.preprocess((v) => v || undefined, z.string().optional()),
  chatToken: z.preprocess((v) => v || undefined, z.string().optional()),
  hosts: z.object({
    urls: z.record(
      z
        .string()
        .regex(
          /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/
        ),
      z.object({
        country: z.enum(countries),
        clean: z.boolean().optional(),
        site: z.string().url().optional(),
        support: z.string().url().optional(),
        pqr: z.string().url().optional(),
        contact: z.string().url().optional(),
        bequicker: z.string().url().optional(),
        lang: z.enum(['es', 'en']).optional(),
        clarity: z.string().optional(),
      })
    ),
    defaults: z.record(
      z.enum(countries),
      z.object({
        site: z.string().url(),
        support: z.string().url(),
        pqr: z.string().url(),
        contact: z.string().url(),
        bequicker: z.string().url().optional(),
        lang: z.enum(['es', 'en']).optional(),
      })
    ),
  }),
  env_tag: z.preprocess((v) => v || undefined, z.string().optional()),
  clarity: z.preprocess((v) => v || undefined, z.string().optional()),
  cancelers: z.preprocess(
    (v) => v || undefined,
    z
      .string()
      .regex(/^(\d+,?)*$/)
      .optional()
  ),
});

type EnvsType = z.infer<typeof Envs>;

export const envs = Envs.parse({
  quickApi: process.env.NEXT_PUBLIC_QUICK_API,
  homologatorsApi: process.env.NEXT_PUBLIC_HOMOLOGATORS_API,
  webhooksApi: process.env.NEXT_PUBLIC_WEBHOOKS_API,
  pushNotifications: process.env.NEXT_PUBLIC_PUSH_NOTIFICATION,
  rtl: process.env.NEXT_PUBLIC_RTL,
  mapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY,
  grecaptchaApiKey: process.env.NEXT_PUBLIC_GRECAPTCHA_API_KEY,
  S3: process.env.NEXT_PUBLIC_S3,
  logWH: process.env.NEXT_PUBLIC_LOG_WH,
  orderBilledUrl: process.env.NEXT_PUBLIC_ORDER_BILLED,
  dashboard: process.env.NEXT_PUBLIC_DASHBOARD,
  chat: process.env.NEXT_PUBLIC_CHAT,
  chatToken: process.env.NEXT_PUBLIC_CHAT_TOKEN,
  hosts: JSON.parse(process.env.NEXT_PUBLIC_HOSTS ?? ''),
  env_tag: process.env.NEXT_PUBLIC_ENV,
  clarity: process.env.NEXT_PUBLIC_CLARITY,
  cancelers: process.env.NEXT_PUBLIC_CANCELERS,
} as EnvsType);

export const MASSIVE_ONHOLD =
  'https://quickapp-storage.s3.amazonaws.com/resources/massive_templates/masivo_espera_new.xlsx';
export const MASSIVE_FINISHED =
  'https://quickapp-storage.s3.amazonaws.com/resources/massive_templates/masivo_finalizado_new.xlsx';

const userOffset = new Date().getTimezoneOffset();
const timezoneHours = `${Math.abs(Math.floor(userOffset / 60))}`.padStart(
  2,
  '0'
);
const timezoneMinutes = `${Math.abs(userOffset % 60)}`.padStart(2, '0');

export const userTimeZoneOffset = `${
  userOffset < 0 ? '+' : '-'
}${timezoneHours}:${timezoneMinutes}`;

export const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const SERVICE_STATES_IDS = {
  validating: 0,
  onHold: 1,
  assigned: 2,
  transit: 3,
  finished: 4,
  cancelled: 5,
  relaunched: 6,
  finishedCancelled: 7,
};

export const SERVICE_DOCUMENT_CATEGORIES = {
  other: 1,
  consignment: 2,
  manifest: 3,
  advance_payment: 6,
  bank_certificate: 7,
};

export const serviceAdvancePaymentDocumentCategories = [
  SERVICE_DOCUMENT_CATEGORIES.consignment,
  SERVICE_DOCUMENT_CATEGORIES.manifest,
];

export const CANCELERS = new Set(
  envs.cancelers
    ?.split(',')
    .filter(Boolean)
    .map((v) => Number(v)) ?? []
);
